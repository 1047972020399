<template>

	<div
		class="section_wrap pt-50 pb-80 "
		:class="{ bg_dark: is_dark_mode }"
		ref="post_list_item_view"
	>
		<div class="cartel-home">
			<!-- 추천 카르텔 -->
			<div class="suggest suggest1">
				<div class="suggest_result">
					<div class="container">
						<div class="swiper cartel_view cartel_view1 " >
							<template
								v-if="items_shorts.length > 0"
							>
								<div class="swiper-wrapper gap-20 swiper-wrpper1 items-center">
									<div
										v-for="(item, index) in list_shorts"
										:key="'item_' + index"
										class="swiper-slide swiper-slice2 radius-20 flex-column justify-center bg-blue02"
									>
										<div class="shorts_item ">
											<a @click="toShorts(item)">
												<div class="picture thumb_st rainbow">
													<img
														:src="$request.upload_url(item.cover_img)"
														@error="$bus.$emit('onErrorCartelProfileImage', $event)"
														class="object-cover"
													/>
												</div>
											</a>
										</div>
									</div>
								</div>
							</template>
							<div
								v-else
								class="cartel_view_none"
							>
								<span class="cont_none">{{ $language.shorts.txt_not_list }} <!-- 등록된 숏플이 없습니다. --></span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- //추천 카르텔 -->
			<!-- 카르텔 썸네일 리스트 -->


			<CartelBbsList
				:user="user"
				:items_list="items_board"
				:max="max"
				:is_add="is_add"
				:item_search="item_search"
				:item_cartel="item_cartel"
				:from="{
					name: 'post'
				}"

				@click="update"
			></CartelBbsList>

			<!-- //카르텔 썸네일 리스트 -->
			<div
				v-if="is_create"
				class="cartel_open"
			>
				<button
					@click="$emit('to', { name: 'mafia119'})"
				>
					<div class="cartel_open_btn">
						<span>{{ $language.common.cartel }}<br><em>{{ $language.common.establish }}</em></span>
					</div>
				</button>
			</div>
		</div>

		<!-- 회원 정보 -->
		<mafia098
			v-if="is_098"
			:cartel="item_cartel"
			:user="user"
			:friend="item_profile"

			@cancel="off098"
			style="position: fixed; left: 0; top: 0; width: 100%; height: 100%; z-index: 9999; background-color: white"
		></mafia098>

		<!-- 더보기 -->
		<mafia064
			v-if="is_more"

			:user="user"
			:item_bbs="item_more"

			@cancel="is_more = false"
			@deleteBbs="deleteBbs"
			@move="moveBbs"
			@modify="onModify"
			@block="doBlock"
			style="z-index: 1003"
		>
		</mafia064>

		<ScrollPagination
			v-if="$refs.post_list_item_view"
			:body="$refs.post_list_item_view"
			:item_search="user.member_number ? item_search.post : item_search"
			:max="max"
			:is_add="is_add"
			:top="$refs.cartel_cover"

			@change="getData"
		></ScrollPagination>
	</div>
</template>
<script>

import mafia064 from "@/view/Cartel/mafia064";
import mafia098 from "@/view/Cartel/mafia098";
import ScrollPagination from "@/components/ScrollPagination";
import CartelBbsList from "@/view/Cartel/CartelBbsList";

export default {
	name: 'CartelPost'
	, props: ['user']
	, components: {CartelBbsList, ScrollPagination, mafia064, mafia098}
	, data: function(){
		return {
			program: {
				name: 'post'
				, title: this.$language.common.cartel + ' ' + this.$language.common.list
				, type: '1'
				, not_footer: false
				, not_header: false
			}
			, items_cartel: []
			, items_shorts: []
			, cartl_open_possible_fg: ''
			, item_search: {
				page_number: this.$language.base.page_number
				, pagerecnum: this.$language.base.pagerecnum
				, post: {
					page_number: this.$language.base.page_number
					, pagerecnum: 20

				}
			}
			, items_board: []
			, is_more: false
			, item_more: {}
			, is_item_detail: false
			, item_cartel: {}
			, item_board: {}
			, is_item_detail_comment: false
			, icons_emoji: [

				{ code: 'CA01100001', type: 'best', txt: this.$language.emoticon.best}
				, { code: 'CA01100002', type: 'funny', txt: this.$language.emoticon.funny}
				, { code: 'CA01100003', type: 'like', txt: this.$language.emoticon.like}
				, { code: 'CA01100004', type: 'sad', txt: this.$language.emoticon.sad}
				, { code: 'CA01100005', type: 'surprise', txt: this.$language.emoticon.surprise}
				, { code: 'CA01100006', type: 'angry', txt: this.$language.emoticon.angry}
			]
			, item_profile: {}
			, is_098: false
			, is_add: true
			, max: false
		}
	}
	, computed: {
		is_create: function(){
			let t = false

			if(this.cartl_open_possible_fg == 'Y'){
				t = true
			}

			return t
		}
		, list_my_cartel: function(){

			return this.items_cartel.filter(function(item){
				if(item.status == 0){
					item.is_confirm = false
				}else{
					item.is_confirm = true
				}
				return item
			})
		}
		, list_bbs: function(){

			return this.items_board.filter((item) => {
				if(item.blok_member_fg == 'Y'){
					item.is_block = true
				}else{
					item.is_block = false
				}
				if(item.sympaty_icon_code != ''){
					item.is_empathy = true
				}
				item.time_t = this.$date.getTimeStory(item.registdate)
				if(item.post_file_list && item.post_file_list.length > 0){
					for(let i = 0; i < item.post_file_list.length; i++){
						// item.post_file_list[i].file_url = item.post_file_list[i].post_appendix_file_partial_list[0].appendix_file_url
						item.post_file_list[i].file_url = item.post_file_list[i].post_appendix_file_org_url
						console.log(item.post_file_list[i].file_url)
					}
				}
				return item
			})
		}
		, is_dark_mode: function(){
			let t = false

			if(this.user.dark_mode == 'Y'){
				t = true
			}
			return t
		}
		, list_shorts: function(){
			return this.items_shorts.filter( (item) => {
				item.post_file_list.filter( (file) => {
					if(file.post_appendix_file_div_code == 'CA01000001'){
						item.cover_img = file.post_appendix_file_org_url
					}
				})
				return item
			})
		}
	}

	, methods: {
		getData: function(page){
			console.log(page)
			if(this.user.member_number){
				this.getMemberData(page)
			}else{
				this.getOpenBbs(page)
			}
			if(page == 1){
				this.getShortData()
			}
		}
		, getOpenBbs: async function(page){
			try{
				this.is_add = false
				this.$bus.$emit('on', true)
				this.$set(this.item_search, 'page_number', page)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_open_cartel_all_bbs_list
					, data: {
						page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){
					if(result.data.entry_cartl_nrt_post_list.length > 0){
						if(this.items_board.length > 0){
							this.items_board = this.items_board.concat(result.data.entry_cartl_nrt_post_list)
						}else{
							this.items_board = result.data.entry_cartl_nrt_post_list
						}
						this.max = false
					}else{
						this.max = true
					}
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				this.is_add = true
			}
		}
		, getShortData: async function(){
			try{
				let url = this.$api_url.api_path.get_open_cartel_shorts_list
				if(this.user.member_number){
					url = this.$api_url.api_path.get_shorts_total_list
				}
				const result = await this.$Request({
					method: 'post'
					, url: url
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.b_id + ''
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){
					this.items_shorts = result.data.post_list
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}
		}
		, getMemberData: async function(page){
			try{
				this.is_add = false
				this.$bus.$emit('on', true)
				this.$set(this.item_search.post, 'page_number', page)
				this.$set(this.item_search.post, 'page_number', page)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_main_info
					, data: {
						member_number: this.user.member_number
						, cartl_list_page: {
							page_number: this.item_search.page_number
							, pagerecnum: this.item_search.pagerecnum
						}
						, nrt_post_list_page: {
							page_number: this.item_search.post.page_number
							, pagerecnum: this.item_search.post.pagerecnum
						}
					}
					, type: true
				})

				if(result.success){
					this.items_cartel = result.data.member_cartl_list

					this.cartl_open_possible_fg = result.data.cartl_open_possible_fg
					if(result.data.entry_cartl_nrt_post_list.length > 0){
						if(this.items_board.length > 0){
							this.items_board = this.items_board.concat(result.data.entry_cartl_nrt_post_list)
						}else{
							this.items_board = result.data.entry_cartl_nrt_post_list
						}
						this.max = false
					}else{
						this.max = true
					}
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				this.is_add = true
			}
		}
		, onMore: function(item, index){
			this.is_more = true
			this.item_more = item
			this.item_more.index = index
		}
		, deleteBbs: async function(){
			this.is_more = false
			this.is_item_detail = false
			this.items_board.splice(this.item_more.index, 1)
		}
		, moveBbs: async function(){
			this.is_more = false
			this.is_delete = false
			await this.mount()
		}
		, onModify: function(){
			this.is_item_modify = true
		}
		, modify: function(){
			this.is_more = false
			this.is_item_modify = false
			// console.log('modify', item)
			// this.getBbs(item)
			if(this.is_item_detail){
				this.is_item_detail = false
				setTimeout(() => {
					this.is_item_detail = true
				}, 5)
			}
		}
		, doBlock: function(type){

			this.items_board = this.items_board.filter((item) => {
				if(item.post_member_number == this.item_more.post_member_number){
					item.blok_member_fg = type ? 'Y' : 'N'
				}
				return item
			})
		}
		, toDetail: function(item, index){

			if(!item.is_block){

				this.item_more = item
				this.item_more.index = index

				// this.is_item_detail = true
				this.is_item_detail_comment = false

				// console.log('toDetail', item, index)

				let path = this.$route.path.split('#')
				// console.log('toDetail', this.$route.path, path)
				if(path[1] == 'detail'){
					this.$router.push(path[0])
				}else{
					this.$router.push(this.$route.path + '#detail')
				}
			}
			// document.location.href = '#detail'

			// this.$bus.$emit('to', { name: 'mafia058', params: { idx: this.$route.params.idx, code: item.board_type_code ? item.board_type_code : this.$route.params.code, b_id: item.board_number, bbs_id: item.bulletin_number, from: 'mafia049'}})
		}
		, toComment: function(item, index){
			if(!item.is_block){
				this.item_more = item
				this.item_more.index = index

				// this.is_item_detail = true
				// this.is_item_detail_comment = true

				this.$router.push(this.$route.path + '#comment')
				// this.$bus.$emit('to', { name: 'mafiaCommentList', params: { idx: this.$route.params.idx, code: item.board_type_code, b_id: item.board_number, bbs_id: item.bulletin_number}})
			}
		}

		, update: function(item){

			// console.log('update', item.index, item)
			this.$set(this.items_board, item.index, {})
			this.$set(this.items_board, item.index, item)
		}

		, postEmoji: async function(item, emoji){
			try{
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_emoji
					, data: {
						member_number: this.user.member_number
						, cartl_number: item.cartl_number
						, board_number: item.board_number
						, bulletin_number: item.bulletin_number
						, sympaty_icon_code: emoji.code
					}
					, type: true
				})

				if(result.success){
					item.is_empathy = emoji.code
					item.sympaty_icon_code = emoji.code
					item.is_emoji = false
					item.sympaty_count++
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.is_emoji = false
				this.$bus.$emit('on', false)
			}
		}
		, on098: function(item){
			this.is_098 = true
			this.item_profile = item
		}
		, off098: function(){
			this.is_098 = false
			this.$bus.$emit('onLoad', this.program)
		}
		, toShorts: function(item){
			this.$bus.$emit('to', { name: 'CartelShortsAll', params: { bbs_id: item.bulletin_number, from: this.$route.name}})
		}
	}
	, async created() {
		// console.log('044 user', this.user)
		this.$emit('onLoad', this.program)
		setTimeout(async () => {
			await this.getData(1)
		}, 100)

		if(await this.$common.getBrowser() === 'Safari'){
			document.body.scrollTop = 0
		}
	}
	, watch:{
		$route(to){
			// console.log(to, from)

			this.is_item_detail = false
			this.is_item_detail_comment = false
			if(to.hash){
				switch (to.hash){
					case '#detail':
						this.is_item_detail = true
						break
					case '#comment':
						this.is_item_detail = true
						this.is_item_detail_comment = true
						break
				}
			}else{
				this.$emit('onLoad', this.program)
			}
		}
	}
}
</script>

<style>
.suggest1 {  padding-bottom: 0 }
.cartel_view1 { overflow: auto; padding-bottom: 10px }
.swiper-wrpper1 { justify-content: flex-start }
.swiper-slice1 { margin-right: 10px; flex-basis: 80px }

.shorts_item {
	max-width: 140px; max-height: 230px;
}
.swiper-slice2 {
	max-width: 140px; height: 230px;
	overflow: hidden;
}
</style>